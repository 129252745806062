import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  Sparklines,
  SparklinesLine,
  SparklinesNormalBand,
  SparklinesSpots,
} from "react-sparklines";
import useWebSocket from "react-use-websocket";
import debounce from "lodash/debounce";
import { Tooltip } from "react-tooltip";
import Button from "./components/button";
import { useNavigate } from "react-router-dom";
import cronstrue from "cronstrue/i18n";
import React, { useState } from "react";
import { getRunner, postRunnerByRunnerIdExec } from "./api-client";
import { getGlobalClient, getJwt } from "./api-client-config";
import useSWR from "swr";
import { Client } from "@hey-api/client-fetch";
import { classNames, makeTimeDiffPretty } from "./utils";
import LogModal from "./components/log-modal";
import SmallCircleSeparator from "./components/small-circle-separator";
import { Loader } from "./components/loader";

const statuses: { [key: string]: string } = {
  Pending: "text-gray-500 bg-gray-100/10",
  Success: "text-green-400 bg-green-400/10",
  Fail: "text-rose-400 bg-rose-400/10",
};
const environments: { [key: string]: string } = {
  Created: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
  Running: "text-yellow-400 bg-yellow-400/10 ring-yellow-400/30",
  // "Idle Error": "text-red-400 bg-red-400/10 ring-red-400/30",
  Idle: "text-green-400 bg-green-400/10 ring-green-400/30",
  Disabled: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
};

const getRunnerFetcher = async (client: Client) => {
  const response = await getRunner({ client });
  // console.log("RESPONSE: ", response.data);
  if (response.error) {
    console.error(response.error);
    return [];
  } else {
    return response.data;
  }
};

export default function Runners() {
  const client = getGlobalClient();
  const jwt = getJwt();
  const navigate = useNavigate();
  const [activeRunId, setActiveRunId] = useState<string | null>(null);
  const [activeRunnerId, setActiveRunnerId] = useState<string | null>(null);
  const [update, setUpdate] = useState<string>("");

  const [logModalOpen, setLogModalOpen] = useState(false);
  const [isLive, setIsLive] = useState(true);
  const {
    data = [],
    error,
    isLoading,
    mutate,
  } = useSWR("/runners", async () => getRunnerFetcher(client), {
    loadingTimeout: 1000,
  });
  const runners = data;

  const baseUrl = process.env.REACT_APP_WS_API_URL || "";
  const socketUrl = `${baseUrl}/prod/?token=${jwt}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      const parsedPayload = JSON.parse(event.data);
      if (parsedPayload.type === "STATUS") {
        console.log("ONMESSAGE: " + JSON.stringify(parsedPayload, null, 2));
        // Just re-render everything for now
        mutate();
      }
    },
  });

  // console.log("READY STATE: ", readyState);
  // console.log("LAST MESSAGE: ", lastMessage);

  if (error || !runners) {
    return <div>Error loading runners</div>;
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // console.log("RUNNERS: ", JSON.stringify(runners));

  // Refactor to useCallback
  function onCreateRunnerButtonClick() {
    navigate("/runners/new");
  }

  // Refactor to useCallback
  function handleOnRunNowClick(runnerId: string) {
    return async () => {
      const response = await postRunnerByRunnerIdExec({
        client,
        path: { runnerId },
      });
      if (response.error) {
        console.error(response.error);
      } else {
        if (response.data.runId) {
          setActiveRunId(response.data.runId);
          setActiveRunnerId(runnerId);
        }
        setIsLive(true);
        setLogModalOpen(true);
        console.log(response.data);
      }
    };
  }
  // const debouncedRunNowClick = debounce(handleOnRunNowClick, 1000, {
  //   leading: true,
  //   trailing: false,
  // });

  // function handleViewLastLogsClick(runnerId: string) {
  //   // get last runId

  //   return () => {
  //     setActiveRunId(runId);
  //     setActiveRunnerId(runnerId);
  //     setLogModalOpen(true);
  //   };
  // }
  function handleViewLastLogsClick(runnerId: string) {
    // Get the lastRunId from runners
    const runner = runners.find((runner) => runner.runnerId === runnerId);
    if (!runner) {
      console.error("Runner not found");
      return;
    }
    const runId = runner.lastRunId;
    if (runId) {
      setActiveRunId(runId);
      setActiveRunnerId(runnerId);
      setIsLive(false);
      setLogModalOpen(true);
    }
  }

  const lastRunTimes = runners.map((runner) => {
    return `Last run ${
      runner.lastRunTime === -1
        ? "never"
        : `${makeTimeDiffPretty(runner.lastRunTime, Date.now())} ago`
    }`;
  });

  // console.log("LAST RUN TIMES: ", lastRunTimes);

  const schedules = runners.map((runner) => {
    // console.log("CRON: ", runner.cron);
    if (runner.cron) {
      return cronstrue.toString(runner.cron);
    }
    return "No schedule";
  });

  const avgRunTimes = runners.map((runner) => {
    const runTimeHistory = runner.lastRunDurations || [];
    const avgRunTime =
      runTimeHistory.length > 0
        ? runTimeHistory.reduce((a, b) => a + b, 0) / runTimeHistory.length
        : 0;
    return avgRunTime;
  });

  const lastRunStates = runners.map((runner) => {
    return runner.lastRunStates.length >= 5
      ? // Only show the last 5 runner states
        runner.lastRunStates.slice(runner.lastRunStates.length - 5)
      : runner.lastRunStates;
  });

  // console.log("UPDATE: ", update);

  return (
    <div className="min-w-[460px]" id={update}>
      {logModalOpen && (
        <LogModal
          open={logModalOpen}
          onClose={() => setLogModalOpen(false)}
          runnerId={activeRunnerId || ""}
          runId={activeRunId || ""}
          isLive={isLive}
        />
      )}
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-white text-xl py-4">Runners</h1>
        <Button onClick={onCreateRunnerButtonClick} text="Create Runner" />
      </div>
      <div className="w-full border-t border-gray-400"></div>
      <ul role="list" className="h-full w-full divide-y divide-white/5">
        {runners.map((runner, idx) => (
          <li
            key={runner.runnerId}
            className="relative flex items-center space-x-4 py-4"
          >
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                {/* @ts-ignore */}
                {/* <div className={classNames(statuses[deployment.status], 'flex-none rounded-full p-1')}>
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div> */}
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                  <div className="flex gap-x-4">
                    <span className="truncate min-w-min h-6">
                      {runner.name}
                    </span>
                    {/* <span className="text-gray-400">-</span> */}
                    {/* <span className="whitespace-nowrap truncate max-w-md h-8 text-gray-400">
                      {deployment.projectName}
                    </span> */}
                    <div
                      className={classNames(
                        environments[runner.runnerState],
                        "rounded-full flex justify-center py-1 px-2 text-xs font-medium ring-1 ring-inset",
                      )}
                    >
                      <p className="flex items-center">{runner.runnerState}</p>
                    </div>
                    <span className="relative inset-0" />
                  </div>
                </h2>
              </div>

              <p className="text-xs pt-1 whitespace-nowrap truncate max-w-md text-gray-400">
                {runner.description}
              </p>

              <div className="mt-1 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p className="min-w-min truncate">{lastRunTimes[idx]}</p>
                <SmallCircleSeparator />
                <p className="whitespace-nowrap">{schedules[idx]}</p>
                <div className="collapse sm:collapse md:visible flex items-center gap-x-3">
                  <SmallCircleSeparator />
                  <p className="whitespace-nowrap text-gray-400 text-xs">
                    {`Avg. run time ${(avgRunTimes[idx] / 1000).toFixed(1)}s`}
                  </p>
                  {runner.lastRunDurations.length > 1 && (
                    <Sparklines
                      data={runner.lastRunDurations}
                      margin={6}
                      svgWidth={60}
                      svgHeight={20}
                    >
                      <SparklinesLine
                        style={{
                          strokeWidth: 2,
                          stroke: "white",
                          fill: "none",
                        }}
                      />
                      <SparklinesSpots
                        size={4}
                        style={{
                          stroke: "white",
                          strokeWidth: 2,
                          fill: "white",
                        }}
                      />
                      <SparklinesNormalBand
                        style={{ fill: "aquamarine", opacity: 0.2 }}
                      />
                    </Sparklines>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center gap-x-3">
                <div
                  data-tooltip-id="run-now-tooltip"
                  data-tooltip-content="Run now"
                  data-tooltip-delay-show={1000}
                  className="group relative cursor-pointer"
                  onClick={handleOnRunNowClick(runner.runnerId)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    className="w-6 h-6 z-auto"
                  >
                    <path
                      className="stroke-gray-400 group-hover:stroke-gray-200"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
                <Tooltip
                  id="run-now-tooltip"
                  place="top"
                  style={{ backgroundColor: "rgb(75 85 99)" }}
                />

                <div
                  data-tooltip-id="view-last-run-tooltip"
                  data-tooltip-content="View last run logs"
                  data-tooltip-delay-show={1000}
                  className="group relative cursor-pointer"
                  onClick={() => handleViewLastLogsClick(runner.runnerId)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    className="w-6 h-6 z-auto"
                  >
                    <path
                      className="stroke-gray-400 group-hover:stroke-gray-200"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                </div>
                <Tooltip
                  id="view-last-run-tooltip"
                  place="top"
                  style={{ backgroundColor: "rgb(75 85 99)" }}
                />

                <div
                  data-tooltip-id="view-history-tooltip"
                  data-tooltip-content="View history"
                  data-tooltip-delay-show={1000}
                  className="group relative cursor-pointer"
                  onClick={() => navigate(`/runners/${runner.runnerId}/runs`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    className="w-6 h-6 z-auto"
                  >
                    <path
                      className="stroke-gray-400 group-hover:stroke-gray-200"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                    />
                  </svg>
                </div>
                <Tooltip
                  id="view-history-tooltip"
                  place="top"
                  style={{ backgroundColor: "rgb(75 85 99)" }}
                />
              </div>

              {/* <div className={classNames(environments[deployment.environment],
                    "rounded-full flex justify-center py-1 px-2 text-xs font-medium ring-1 ring-inset",
                  )}
                >
                  <p className="flex items-center">{deployment.environment}</p>
                </div> */}
              <div className="flex align-center pt-8 pb-0 gap-x-1">
                {lastRunStates[idx].map((status, i: number) => {
                  return (
                    <div
                      key={i}
                      className={classNames(
                        statuses[status],
                        "flex-none rounded-full p-1",
                      )}
                    >
                      <div className="h-2 w-2 rounded-full bg-current" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={() => navigate(`/runners/${runner.runnerId}`)}
              data-tooltip-id="view-detail-tooltip"
              data-tooltip-content="View/Edit details"
              data-tooltip-delay-show={1000}
              className="group cursor-pointer"
            >
              <ChevronRightIcon
                className="h-10 w-10 flex-none text-gray-400 group-hover:text-gray-200 z-auto"
                aria-hidden="true"
              />
            </div>
            <Tooltip
              id="view-detail-tooltip"
              place="left"
              style={{ backgroundColor: "rgb(75 85 99)" }}
            />
          </li>
        ))}
      </ul>
      <div className="w-full border-t border-gray-700"></div>
    </div>
  );
}
