import React from "react";

interface Props {
  loadingText?: string;
}

export function Loader({ loadingText = "Loading" }: Props) {
  return (
    <div className="p-2.5 text-blue-300">
      <div>
        <span className="mr-2">{loadingText}</span>
        <span className="ml-1 mr-1 animate-[ping_1.5s_0.5s_ease-out_infinite]">
          .
        </span>
        <span className="ml-1 mr-1 animate-[ping_1.5s_0.7s_ease-out_infinite]">
          .
        </span>
        <span className="ml-1 mr-1 animate-[ping_1.5s_0.9s_ease-out_infinite]">
          .
        </span>
      </div>
    </div>
  );
}
