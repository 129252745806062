import { Client } from "@hey-api/client-fetch";
import { getSecret } from "./api-client";
import { getGlobalClient, getJwt } from "./api-client-config";
import useSWR from "swr";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Button from "./components/button";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { extractTimestampFromUUIDv7 } from "./utils";
import { Loader } from "./components/loader";

const getSecretFetcher = async (client: Client) => {
  const response = await getSecret({ client });
  // console.log("RESPONSE: ", response.data);
  if (response.error) {
    console.error(response.error);
    return [];
  } else {
    return response.data.secrets;
  }
};

export default function Secrets() {
  const navigate = useNavigate();
  const client = getGlobalClient();
  const jwt = getJwt();
  const {
    data: secrets,
    error,
    isLoading,
  } = useSWR("secrets", () => getSecretFetcher(client), {
    loadingTimeout: 1000,
  });

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error || !secrets) {
    return <div>Error: {error}</div>;
  }

  function onCreateSecretButtonClick() {
    navigate("/secrets/new");
  }

  const createdTimestamps = secrets.map((secret) => {
    const timestamp = extractTimestampFromUUIDv7(secret.secretId);
    return timestamp.toLocaleString();
  });

  return (
    <div className="min-w-[460px]">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-white text-xl py-4">Secrets</h1>
        <Button onClick={onCreateSecretButtonClick} text="Create Secret" />
      </div>
      <div className="w-full border-t border-gray-400" />
      <ul role="list" className="h-full w-full divide-y divide-white/5">
        {secrets.map((secret, idx) => (
          <li
            key={secret.secretId}
            className="relative flex items-center space-x-4 py-4"
          >
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                  <div className="flex gap-x-4">
                    <span className="truncate min-w-min h-6">
                      {secret.secretName}
                    </span>
                  </div>
                </h2>
              </div>

              <p className="text-xs pt-1 whitespace-nowrap truncate max-w-md text-gray-400">
                {secret.description}
              </p>

              <div className="mt-1 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p className="min-w-min truncate">
                  Created {createdTimestamps[idx]}
                </p>
                <svg
                  viewBox="0 0 2 2"
                  className="h-0.5 w-0.5 flex-none fill-gray-300"
                >
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="whitespace-nowrap">Schedules</p>
                <div className="collapse sm:collapse md:visible flex items-center gap-x-3">
                  <svg
                    viewBox="0 0 2 2"
                    className="h-0.5 w-0.5 flex-none fill-gray-300"
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="whitespace-nowrap text-gray-400 text-xs">
                    {`Avg. run time 1000s`}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between h-full">
              <div className="flex align-center pt-8 pb-0 gap-x-1"></div>
            </div>
            <div
              onClick={() => navigate(`/secrets/${secret.secretId}`)}
              data-tooltip-id="view-detail-tooltip"
              data-tooltip-content="View/Edit secret"
              data-tooltip-delay-show={1000}
              className="group cursor-pointer"
            >
              <ChevronRightIcon
                className="h-10 w-10 flex-none text-gray-400 group-hover:text-gray-200 z-auto"
                aria-hidden="true"
              />
            </div>
            <Tooltip
              id="view-detail-tooltip"
              place="left"
              style={{ backgroundColor: "rgb(75 85 99)" }}
            />
          </li>
        ))}
      </ul>
      /
    </div>
  );
}
