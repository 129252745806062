import { Client } from "@hey-api/client-fetch";
import { getRunnerByRunnerId, getRunnerByRunnerIdRun } from "./api-client";
import { getGlobalClient } from "./api-client-config";
import React, { useState } from "react";
import useSWR from "swr";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { classNames } from "./utils";
import LogModal from "./components/log-modal";
import SmallCircleSeparator from "./components/small-circle-separator";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Loader } from "./components/loader";

const runStatuses: { [key: string]: string } = {
  Pending: "text-gray-500 bg-gray-100/10",
  Success: "text-green-400 bg-green-400/10",
  Fail: "text-rose-400 bg-rose-400/10",
};

const getRunsFetcher = async (client: Client, runnerId: string) => {
  const response = await getRunnerByRunnerIdRun({ client, path: { runnerId } });

  if (response.error) {
    console.error(response.error);
    throw new Error("Failed to fetch runs: " + response.error);
  } else {
    return response.data;
  }
};

const getRunnerFetcher = async (client: Client, runnerId: string) => {
  const response = await getRunnerByRunnerId({ client, path: { runnerId } });

  if (response.error) {
    console.error(response.error);
    throw new Error("Failed to fetch runs: " + response.error);
  } else {
    return response.data;
  }
};

export default function Runs() {
  const client = getGlobalClient();
  const { runnerId } = useParams();
  const [logModalOpen, setLogModalOpen] = React.useState(false);
  const [activeRunId, setActiveRunId] = React.useState("");
  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `/runner/${runnerId}/run`,
    async () => getRunsFetcher(client, runnerId || ""),
    {
      loadingTimeout: 1000,
    },
  );
  const {
    data: runnerData,
    error: runnerError,
    isLoading: runnerLoading,
  } = useSWR(`/runner/${runnerId}`, async () =>
    getRunnerFetcher(client, runnerId || ""),
  );

  function handleViewLastLogsClick(runId: string) {
    setActiveRunId(runId);
    setLogModalOpen(true);
  }

  if (isLoading || runnerLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!data || error) {
    return <div>Error: {error}</div>;
  }

  const runs = data.runs || [];

  return (
    <>
      {logModalOpen && (
        <LogModal
          open={logModalOpen}
          onClose={() => setLogModalOpen(false)}
          runnerId={runnerId || ""}
          runId={activeRunId || ""}
          isLive={false}
        />
      )}
      <div className="flex items-center justify-between">
        <nav className="flex my-4 text-lg" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/runners"
                  className="text-gray-400 hover:text-gray-200"
                >
                  Runners
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div className="ml-4 text-md text-gray-400" aria-current={true}>
                  {runnerData ? runnerData.name : runnerId}
                </div>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div className="ml-4 text-md text-gray-400" aria-current={true}>
                  Runs
                </div>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="w-full border-t border-gray-400"></div>
      <ul role="list" className="h-full w-full divide-y divide-white/5">
        {runs.map((run) => (
          <li
            key={run.runId}
            className="relative flex items-center space-x-4 py-4"
          >
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                <div
                  className={classNames(
                    runStatuses[run.runState],
                    "rounded-full flex justify-center py-1 px-2 text-xs font-medium ring-1 ring-inset",
                  )}
                >
                  <p className="flex items-center">{run.runState}</p>
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                  <div className="flex gap-x-4">
                    <span className="truncate min-w-min h-6">{run.runId}</span>
                  </div>
                </h2>
              </div>

              <div className="mt-1 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p className="min-w-min truncate">
                  Started {new Date(run.timeStarted).toLocaleString()}
                </p>
                <SmallCircleSeparator />
                <p className="min-w-min truncate">
                  Stopped {new Date(run.timeStopped).toLocaleString()}
                </p>
                <SmallCircleSeparator />
                <p className="min-w-min truncate">{`Run time: ${formatTimeDifference(
                  run.timeStarted,
                  run.timeStopped,
                )}`}</p>
              </div>
            </div>

            <div className="flex flex-col justify-between h-full">
              <div className="flex align-center gap-x-1">
                <div
                  data-tooltip-id="view-history-tooltip"
                  data-tooltip-content="View history"
                  data-tooltip-delay-show={1000}
                  className="group relative cursor-pointer"
                  onClick={() => handleViewLastLogsClick(run.runId)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    className="w-7 h-7 z-auto"
                  >
                    <path
                      className="stroke-gray-400 group-hover:stroke-gray-200"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                    />
                  </svg>
                </div>
                <Tooltip
                  id="view-history-tooltip"
                  place="top"
                  style={{ backgroundColor: "rgb(75 85 99)" }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="w-full border-t border-gray-700"></div>
    </>
  );
}

function formatTimeDifference(timeStarted: number, timeStopped: number) {
  const minutes = Math.floor((timeStopped - timeStarted) / 1000 / 60);
  if (minutes > 60) {
    return `${Math.floor(minutes / 60)} hours ${minutes % 60} minutes`;
  }
  return minutes < 1
    ? `${Math.floor((timeStopped - timeStarted) / 1000)} seconds`
    : `${minutes} minutes`;
}
