import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { getRunnerByRunnerIdRunByRunIdLogByOffset } from "../api-client";
import useWebSocket from "react-use-websocket";
import { getJwt } from "../api-client-config";
import { sleep } from "../utils";
import { Loader } from "./loader";

const MAX_GET_LOG_TIMEOUT = 10000;

interface LogModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  runnerId: string;
  runId: string;
  isLive: boolean;
}

export default function LogModal({
  open,
  onClose,
  runnerId,
  runId,
  isLive,
}: LogModalProps) {
  const [offset, setOffset] = useState("0");
  const [log, setLog] = useState<string[]>([]);
  const [pollingDone, setPollingDone] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const jwt = getJwt();
  const baseUrl = process.env.REACT_APP_WS_API_URL || "";
  const socketUrl = `${baseUrl}/prod/?token=${jwt}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      const parsedPayload = JSON.parse(event.data);
      if (parsedPayload.type === "LOG") {
        // console.log("ONMESSAGE: " + JSON.stringify(parsedPayload, null, 2));
        const parsedMessage = JSON.parse(parsedPayload.message);
        const newLog = parsedMessage.logs.reduce(
          (acc: string, log: { log: string }) => acc.concat(log.log),
          [],
        );
        setLog((prevLog) => prevLog.concat(newLog));
        setTimeout(async () => {
          // if (scrollRef.current) {
          //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          // }
          if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }
        }, 0);
      }
    },
  });
  // console.log("Last message: " + lastMessage?.data);

  async function getLog(newOffset?: string) {
    if (!Number.isNaN(newOffset) && newOffset) {
      const response = await getRunnerByRunnerIdRunByRunIdLogByOffset({
        path: {
          runnerId,
          runId,
          offset: newOffset,
        },
      });
      if (response.error) {
        console.error(response.error);
        if (response.response.status === 404) {
          return {
            nextOffset: "0",
          };
        }
        throw new Error("Error getting logs");
      } else {
        if (response.data.nextOffset) {
          // console.log("Next offset: " + response.data.nextOffset);
          // setOffset(response.data.nextOffset);
          const newLog = response.data.logs.reduce(
            (acc, log) => acc.concat(log.log),
            log,
          );

          // console.log("newLog: " + newLog);
          // setLog(combinedLogs);
          setLog((prevLog) => prevLog.concat(newLog));
          setTimeout(async () => {
            // if (scrollRef.current) {
            //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            // }
            scrollRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }, 0);
          return {
            nextOffset: response.data.nextOffset,
            newLog,
          };
        } else {
          console.log("No more logs");
          if (response.data.logs && response.data.logs.length > 0) {
            const newLog = response.data.logs.reduce(
              (acc, log) => acc.concat(log.log),
              log,
            );
            // console.log("newLog: " + newLog);
            setLog((prevLog) => prevLog.concat(newLog));
          }
          setTimeout(async () => {
            // if (scrollRef.current) {
            //   scrollRef.current.scrollTop = scrollRef.current.clientHeight;
            // }
            scrollRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }, 0);
        }
      }
    }
    return {
      nextOffset: undefined,
    };
  }

  useEffect(() => {
    async function getAllLogs() {
      console.log("Getting all logs");
      let timeoutCntr = 0;
      let prevOffset = "0";
      while (timeoutCntr < MAX_GET_LOG_TIMEOUT) {
        timeoutCntr += 1;
        console.log("Timeout counter: " + timeoutCntr);
        const { nextOffset } = await getLog(prevOffset);
        console.log("Next offset: " + nextOffset);

        if (nextOffset) {
          const newOffsetNum = parseInt(nextOffset, 10);
          if (newOffsetNum === 0) {
            await sleep(1000);
          }
          prevOffset = nextOffset;
        } else {
          break;
        }
      }
    }
    if (!isLive) {
      getAllLogs();
    }

    return () => {
      console.log("Cleanup");
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="flex justify-center relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex justify-center min-h-full sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="overflow-hidden min-w-[calc(100%-60px)] transform rounded-lg bg-gray-800 px-2 pb-2 pt-2 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in xs:p-1 sm:w-full sm:max-w-lg sm:pt-3 sm:p-4 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex justify-between overflow-hidden align-middle h-9 border-b border-gray-500">
              <div className="text-base font-semibold leading-6 text-gray-100">
                Run {runId} Logs
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={() => onClose(false)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="mt-3 sm:ml-4 sm:mt-0 w-svw h-[calc(100vh-120px)] overflow-scroll">
              {log.length === 0 ? (
                <Loader loadingText="Booting up runtime" />
              ) : (
                log.map((line, index) => {
                  return (
                    <p
                      key={index}
                      className="mt-2 text-gray-100 text-xs font-mono whitespace-pre"
                    >
                      {line}
                    </p>
                  );
                })
              )}
              <div className="h-2 w-2" ref={scrollRef} />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
